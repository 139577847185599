import {LegalDisclaimerStandalone} from '@smart/editorial-components';
import type {LegalDisclaimersContent} from '@smart/website-aem-utils';
import * as React from 'react';
import {createDisclamerLinkProps} from '../utils/universal/create-disclamer-link-props';

export interface LegalDisclaimersWrapperProps {
  readonly content: LegalDisclaimersContent;
}

export const LegalDisclaimersWrapper = React.memo(
  ({content}: LegalDisclaimersWrapperProps) => (
    <LegalDisclaimerStandalone
      mode={content.mode}
      legalDisclaimers={createDisclamerLinkProps(content.legalDisclaimers)}
    />
  ),
  (prevProps, nextProps) => prevProps.content.id === nextProps.content.id,
);

LegalDisclaimersWrapper.displayName = `LegalDisclaimersWrapper`;
