import {FullWidthTeaser} from '@smart/editorial-components';
import type {FullWidthTeaserContent} from '@smart/website-aem-utils';
import * as React from 'react';
import {useLink} from '../hooks/use-link';
import {createDisclamerLinkProps} from '../utils/universal/create-disclamer-link-props';

export interface FullWidthTeaserWrapperProps {
  readonly content: FullWidthTeaserContent;
}

export const FullWidthTeaserWrapper = React.memo(
  ({content}: FullWidthTeaserWrapperProps) => {
    const link = useLink(content.link);
    const {headline, copyText, image, legalDisclaimers} = content;

    return (
      <FullWidthTeaser
        headline={headline}
        copyText={copyText}
        image={image}
        link={link}
        legalDisclaimers={createDisclamerLinkProps(legalDisclaimers)}
      />
    );
  },
  (prevProps, nextProps) => prevProps.content.id === nextProps.content.id,
);

FullWidthTeaserWrapper.displayName = `FullWidthTeaserWrapper`;
