import type {
  ButtonType,
  SocialMediaNavigationItem,
} from '@smart/web-components';
import {Footer} from '@smart/web-components';
import type {FooterContent} from '@smart/website-page-model';
import * as React from 'react';
import {useFeatureServices} from '../hooks/use-feature-services';
import {useLanguageContext} from '../hooks/use-language-context';
import {useLink} from '../hooks/use-link';
import {useLinks} from '../hooks/use-links';
import {getOneTrustObject} from '../utils/client/get-one-trust-object';
import {pushClickEvent} from '../utils/client/push-click-event';
import {globalGatewayPageUrls} from '../utils/universal/env-consts';
import {PageModelContext} from './page';
import logoSrc from './smart-logo-white.svg';

export interface FooterWrapperProps {
  readonly content: FooterContent;
}

export const FooterWrapper = React.memo(
  ({content}: FooterWrapperProps) => {
    const pageModel = React.useContext(PageModelContext);
    const {endpointDirectory, historyService, loginService} =
      useFeatureServices();
    const logoLink = useLink(content.logoLink);
    const cta = useLink(content.cta, {
      pageModel,
      eventTrackingData: {
        type: `navigation`,
        region: `footer`,
        name: content.cta?.label || ``,
      },
    });
    const useLinksOptions = {
      pageModel,
      eventTrackingData: {
        type: `navigation`,
        region: `footer`,
      },
      envName: endpointDirectory.envName,
      loginState: loginService?.loginState,
    };
    const linkGroup1 = useLinks(content.linkGroup1 ?? [], useLinksOptions);
    const linkGroup2 = useLinks(content.linkGroup2 ?? [], useLinksOptions);
    const linkGroup3 = useLinks(content.linkGroup3 ?? [], useLinksOptions);
    const termsAndStatements = useLinks(
      content.termsAndStatements ?? [],
      useLinksOptions,
    );

    const {
      headline,
      subHeadline,
      copyText,
      copyright,
      socialMediaNavigationLabel,
      localeSwitch,
      cookieConsentLabel,
      socialMediaChannels,
      linkGroup1Title,
      linkGroup2Title,
      linkGroup3Title,
    } = content;

    const {selectedLanguage, setSelectedLanguage} = useLanguageContext();
    const [_, startLanguageChangeTransition] = React.useTransition();

    const handleLanguageChange: React.ChangeEventHandler<HTMLSelectElement> = (
      event,
    ) => {
      setSelectedLanguage(event.target.value);
      startLanguageChangeTransition(() => {
        historyService.rootHistory.push({pathname: `/${event.target.value}/`});
      });
    };

    const cookieConsentButton = React.useMemo<ButtonType | undefined>(() => {
      if (!cookieConsentLabel) {
        return undefined;
      }

      return {
        label: cookieConsentLabel,
        onClick: () => {
          getOneTrustObject()?.ToggleInfoDisplay();

          const {pathname, search} = historyService.rootHistory.location;
          const currentUrl = new URL(
            historyService.rootHistory.createHref({pathname, search}),
            document.location.origin,
          ).href;

          if (pageModel) {
            pushClickEvent(
              endpointDirectory.envName,
              loginService?.loginState,
              pageModel,
              {
                type: `navigation`,
                region: `footer`,
                name: cookieConsentLabel,
                url: currentUrl || ``,
                destinationUrl: ``,
              },
            );
          }
        },
      };
    }, [cookieConsentLabel]);

    const socialMediaNavigationItems = React.useMemo(
      () =>
        socialMediaChannels.map(
          ({type, url}): SocialMediaNavigationItem => ({
            type,
            href: url,
            rel: `nofollow noopener`,
          }),
        ),
      [],
    );

    return (
      <Footer
        logoSrc={logoSrc}
        logoLink={logoLink}
        headline={headline}
        subHeadline={subHeadline}
        cta={cta}
        linkGroup1Title={linkGroup1Title}
        linkGroup1={linkGroup1}
        linkGroup2Title={linkGroup2Title}
        linkGroup2={linkGroup2}
        linkGroup3Title={linkGroup3Title}
        linkGroup3={linkGroup3}
        termsAndStatements={termsAndStatements}
        copyText={copyText}
        copyright={copyright}
        socialMediaNavigationLabel={socialMediaNavigationLabel}
        socialMediaNavigationItems={socialMediaNavigationItems}
        countryLink={
          localeSwitch && {
            label: localeSwitch.countryName,
            ariaLabel: localeSwitch.countryLinkLabel,
            href: globalGatewayPageUrls[endpointDirectory.envName],
          }
        }
        languageSwitch={
          localeSwitch?.languageSwitchLabel &&
          localeSwitch.languageOptions.length > 1
            ? {
                label: localeSwitch.languageSwitchLabel,
                value: selectedLanguage,
                onChange: handleLanguageChange,
                options: localeSwitch.languageOptions,
              }
            : undefined
        }
        cookieConsentButton={cookieConsentButton}
      />
    );
  },
  (prevProps, nextProps) => prevProps.content.id === nextProps.content.id,
);

FooterWrapper.displayName = `FooterWrapper`;
