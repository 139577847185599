import type {modeType} from '@smart/editorial-components';
import {Video} from '@smart/editorial-components';
import {useInitialIntersection} from '@smart/web-components';
import type {VideoPlayerContent} from '@smart/website-aem-utils';
import * as React from 'react';
import {createDisclamerLinkProps} from '../utils/universal/create-disclamer-link-props';
export interface VideoPlayerWrapperProps {
  readonly content: VideoPlayerContent;
  readonly cdnOrigin: string;
}

const customVideoStylesBaseUrl = process.env.CUSTOM_VIDEO_STYLES_BASE_URL!;

export const VideoPlayerWrapper = React.memo(
  ({content, cdnOrigin}: VideoPlayerWrapperProps) => {
    const videoRef = React.useRef<HTMLIFrameElement | null>(null);
    const [isInView, setIsInView] = React.useState(false);
    const handleIntersection = React.useCallback(() => setIsInView(true), []);

    useInitialIntersection(videoRef, handleIntersection, `100%`);

    return (
      <Video
        mode={content.modeSelector as modeType}
        altText={content.altText}
        headline={content.headline}
        video={{
          url: content.videoUrl,
          posterImageUrl: content.posterImageUrl,
          customVideoStylesBaseUrl: new URL(customVideoStylesBaseUrl, cdnOrigin)
            .href,
          autoPlay: content.autoPlay,
          loop: content.autoLoop,
        }}
        videoRef={videoRef}
        isInView={isInView}
        legalDisclaimers={createDisclamerLinkProps(content.legalDisclaimers)}
      />
    );
  },
  (prevProps, nextProps) =>
    prevProps.content.id === nextProps.content.id &&
    prevProps.cdnOrigin === nextProps.cdnOrigin,
);

VideoPlayerWrapper.displayName = `VideoPlayerWrapper`;
