import type {ITeaserGroupItem} from '@smart/editorial-components';
import {TeaserGroup} from '@smart/editorial-components';
import type {TeaserGroupContent} from '@smart/website-aem-utils';
import * as React from 'react';
import {useFeatureServices} from '../hooks/use-feature-services';
import {createDisclamerLinkProps} from '../utils/universal/create-disclamer-link-props';
import {createLinkProps} from '../utils/universal/create-link-props';
export interface TeaserGroupWrapperProps {
  readonly content: TeaserGroupContent;
}

export const TeaserGroupWrapper = React.memo(
  ({content}: TeaserGroupWrapperProps) => {
    const {historyService} = useFeatureServices();

    const {
      componentHeadline,
      items,
      indicatorBar,
      highlight,
      legalDisclaimers,
    } = content;

    const teaserGroupItems = React.useMemo(
      () =>
        items.map<ITeaserGroupItem>(
          ({headline, teaserText, teaserImage, secondaryImage, link}) => ({
            headline,
            teaserText,
            image: teaserImage,
            secondaryImage,
            cta: link && createLinkProps({historyService, content: link}),
          }),
        ),
      [items],
    );

    return (
      <TeaserGroup
        componentHeadline={componentHeadline}
        teaserGroupItems={teaserGroupItems}
        indicatorBar={indicatorBar}
        highlightHeadline={highlight}
        legalDisclaimers={createDisclamerLinkProps(legalDisclaimers)}
      />
    );
  },
  (prevProps, nextProps) => prevProps.content.id === nextProps.content.id,
);

TeaserGroupWrapper.displayName = `TeaserGroupWrapper`;
