import {VideoStage} from '@smart/editorial-components';
import type {VideoStageContent} from '@smart/website-aem-utils';
import * as React from 'react';
import {useInStageNavigationItems} from '../hooks/use-in-stage-navigation-items';
import {useLink} from '../hooks/use-link';
import {createDisclamerLinkProps} from '../utils/universal/create-disclamer-link-props';
export interface VideoStageWrapperProps {
  readonly content: VideoStageContent;
  readonly cdnOrigin: string;
  readonly pathname: string;
}

const customVideoStylesBaseUrl = process.env.CUSTOM_VIDEO_STYLES_BASE_URL!;

export const VideoStageWrapper = React.memo(
  React.forwardRef<HTMLElement, VideoStageWrapperProps>(
    ({content, cdnOrigin, pathname}, ref) => {
      const {
        headline,
        videoLandscapeUrl,
        videoPortraitUrl,
        videoControls,
        autoPlay,
        autoLoop,
        posterImageUrl,
        altText,
        addOn,
        legalDisclaimers,
      } = content;

      const link = useLink(content.link);

      const inStageNavigationItems = useInStageNavigationItems({
        addOn,
        pathname,
      });

      return (
        <VideoStage
          headline={headline}
          customVideoStylesBaseUrl={
            new URL(customVideoStylesBaseUrl, cdnOrigin).href
          }
          landscapeVideoUrl={videoLandscapeUrl}
          portraitVideoUrl={videoPortraitUrl}
          videoControls={videoControls}
          videoProps={{autoPlay, loop: autoLoop}}
          posterImageUrl={posterImageUrl}
          altText={altText}
          inStageNavigation={inStageNavigationItems}
          priceTag={addOn?.type === `price-tag` ? addOn.priceTag : undefined}
          link={link}
          legalDisclaimers={createDisclamerLinkProps(legalDisclaimers)}
          ref={ref}
        />
      );
    },
  ),
  (prevProps, nextProps) =>
    prevProps.content.id === nextProps.content.id &&
    prevProps.cdnOrigin === nextProps.cdnOrigin &&
    prevProps.pathname === nextProps.pathname,
);

VideoStageWrapper.displayName = `VideoStageWrapper`;
