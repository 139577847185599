import type {LegalDisclaimerContent} from '@smart/website-aem-utils';
import {useFeatureServices} from '../../hooks/use-feature-services';
import {createLinkProps} from './create-link-props';

export const createDisclamerLinkProps = (
  legalDisclaimers: LegalDisclaimerContent[],
) => {
  const {historyService} = useFeatureServices();

  return legalDisclaimers?.map((legalDisclaimer) => {
    return {
      ...legalDisclaimer,
      imageLink: legalDisclaimer.imageLink
        ? createLinkProps({
            content: legalDisclaimer.imageLink,
            historyService,
          })
        : undefined,
    };
  });
};
