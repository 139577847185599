import type {TableType, modeType} from '@smart/editorial-components';
import {Table} from '@smart/editorial-components';
import type {TableContent} from '@smart/website-aem-utils';
import * as React from 'react';
import {useLink} from '../hooks/use-link';
import {createDisclamerLinkProps} from '../utils/universal/create-disclamer-link-props';
import {PageModelContext} from './page';

export interface TableWrapperProps {
  readonly content: TableContent;
}

export const TableWrapper = React.memo<TableWrapperProps>(
  ({content}) => {
    const pageModel = React.useContext(PageModelContext);
    const props: TableType = {
      removeTopSpacing: content.removeTopSpacing || false,
      mode: content.mode as modeType,
      subline: content.subline,
      headline: content.headline,
      layoutAlign: content.layoutAlign,
      hasHeaderRow: content.hasHeaderRow || false,
      hasHeaderColumn: content.hasHeaderColumn || false,
      headerRow: content.headerRow,
      headerColumn: content.headerColumn,
      dataSource: content.dataSource,
      disclaimers:
        (content.disclaimers &&
          createDisclamerLinkProps(content.disclaimers)) ||
        [],
      link: useLink(content.link, {
        pageModel,
        eventTrackingData: {
          type: `navigation`,
          region: `table`,
          name: content.link?.label || ``,
        },
      }),
    };

    return <Table {...props} />;
  },
  (prevProps, nextProps) => prevProps.content.id === nextProps.content.id,
);

Table.displayName = `Table`;
