import {ImageStageSmall} from '@smart/editorial-components';
import type {ImageStageSmallContent} from '@smart/website-aem-utils';
import * as React from 'react';
import {createDisclamerLinkProps} from '../utils/universal/create-disclamer-link-props';

export interface ImageStageSmallWrapperProps {
  readonly content: ImageStageSmallContent;
}

export const ImageStageSmallWrapper = React.memo(
  React.forwardRef<HTMLElement, ImageStageSmallWrapperProps>(
    ({content}, ref) => {
      const {headline, image, legalDisclaimers} = content;

      return (
        <ImageStageSmall
          headline={headline}
          image={image}
          legalDisclaimers={createDisclamerLinkProps(legalDisclaimers)}
          ref={ref}
        />
      );
    },
  ),
  (prevProps, nextProps) => prevProps.content.id === nextProps.content.id,
);

ImageStageSmallWrapper.displayName = `ImageStageSmallWrapper`;
