import {Banner, type BannerHandler} from '@smart/editorial-components';
import {useScrollPosition} from '@smart/web-components';
import type {BannerContent} from '@smart/website-page-model';
import * as React from 'react';
import {useLink} from '../hooks/use-link';
import {PageModelContext} from './page';

export interface BannerWrapperProps {
  readonly content?: BannerContent;
  readonly setIsBannerVisible: (visible: boolean) => void;
}

export const BannerWrapper = React.memo(
  ({content, setIsBannerVisible}: BannerWrapperProps) => {
    const closedBannerSessionKey = `closed-banner`;
    const {
      headLine,
      theme,
      image,
      text,
      cta,
      showCloseButton,
      closeButtonLabel,
    } = content || {};
    const pageModel = React.useContext(PageModelContext);
    const [showBanner, setShowBanner] = React.useState(false);

    const link = useLink(cta, {
      pageModel,
      eventTrackingData: {
        type: `navigation`,
        region: `banner`,
        name: cta?.label || ``,
      },
    });
    const bannerRef = React.useRef<BannerHandler>(null);

    useScrollPosition(({currPos}) => {
      if (currPos.y > -10) {
        bannerRef.current?.fadeIn();
        if (showBanner) {
          setIsBannerVisible(true);
        }
      } else {
        bannerRef.current?.fadeOut();
        setIsBannerVisible(false);
      }
    });

    React.useEffect(() => {
      const closedBannerContenId = localStorage.getItem(closedBannerSessionKey);
      if (content == null || closedBannerContenId?.includes(content.id)) {
        setShowBanner(false);
        setIsBannerVisible(false);
      } else {
        setShowBanner(true);
        setIsBannerVisible(true);
      }
    }, [content]);

    const props = {
      text,
      headLine,
      theme,
      image,
      link,
      showCloseButton,
      closeButtonLabel,
      showBanner,
      close: () => {
        if (content?.id) {
          const closedBannerContenId =
            localStorage.getItem(closedBannerSessionKey)?.split(`,`) || [];
          if (!closedBannerContenId?.includes(content.id)) {
            closedBannerContenId.push(content.id);
            localStorage.setItem(
              closedBannerSessionKey,
              closedBannerContenId.join(`,`),
            );
          }
          setShowBanner(false);
          setIsBannerVisible(false);
        }
      },
    };

    return <> {content && <Banner ref={bannerRef} {...props} />}</>;
  },
  (prevProps, nextProps) => prevProps.content?.id === nextProps.content?.id,
);

BannerWrapper.displayName = `BannerWrapper`;
