import {Header} from '@smart/web-components';
import type {HeaderRef} from '@smart/web-components/dist/types/components/header';
import type {HeaderContent} from '@smart/website-page-model';
import * as React from 'react';
import {useFeatureServices} from '../hooks/use-feature-services';
import {useGarageDetails} from '../hooks/use-garage-details';
import {useHeaderNavigationItems} from '../hooks/use-header-navigation-items';
import {useIsLoggedIn} from '../hooks/use-is-logged-in';
import {useLink} from '../hooks/use-link';
import {useShoppingCartDetails} from '../hooks/use-shopping-cart-details';

export interface HeaderWrapperProps {
  readonly content: HeaderContent;
  readonly isInverted: boolean;
}

export const HeaderWrapper = React.memo(
  ({content, isInverted}: HeaderWrapperProps) => {
    const {historyService} = useFeatureServices();
    const headerRef = React.useRef<HeaderRef>(null);
    const isLoggedIn = useIsLoggedIn();

    const closeMenu = React.useCallback(
      () => headerRef.current?.closeMenu(),
      [],
    );

    React.useEffect(() => historyService.history.listen(closeMenu), []);

    const logoLink = useLink(content.logoLink);
    const cta = useLink(content.cta, {onBeforePush: closeMenu});

    const myAccountLink = useLink(content.myAccountLink, {
      onBeforePush: closeMenu,
    });

    const shoppingCartLink = useLink(content.shoppingCartLink, {
      onBeforePush: closeMenu,
    });

    const shoppingCartDetails = useShoppingCartDetails({
      enabled: shoppingCartLink !== undefined,
    });

    const myGarageLink = useLink(content.myGarageLink, {
      consumerLocations: [
        {historyKey: `my-account`, location: {pathname: `/garage`}},
      ],
      onBeforePush: closeMenu,
    });

    const garageDetails = useGarageDetails({
      enabled: myGarageLink !== undefined,
    });

    const navigationItems = useHeaderNavigationItems({
      navigationItemContents: content.navigationItems,
      logoutNavigationLinkLabel: content.logoutNavigationLinkLabel,
      isLoggedIn,
      closeMenu,
    });

    const {menuButtonLabel, closeButtonLabel} = content;

    return (
      <Header
        logoLink={logoLink}
        navigationItems={navigationItems}
        cta={cta}
        openMenuAriaLabel={menuButtonLabel}
        closeMenuAriaLabel={closeButtonLabel}
        myAccountLink={myAccountLink}
        myGarageBadge={garageDetails?.itemCount}
        myGarageLink={myGarageLink}
        myShoppingBadge={shoppingCartDetails?.itemCount}
        myShoppingLink={shoppingCartLink}
        isLoggedIn={isLoggedIn}
        isInverted={isInverted}
        ref={headerRef}
      />
    );
  },
  (prevProps, nextProps) =>
    prevProps.content.id === nextProps.content.id &&
    prevProps.isInverted === nextProps.isInverted,
);

HeaderWrapper.displayName = `HeaderWrapper`;
