import type {
  ConsumerLocationV3,
  HistoryServiceV3,
} from '@feature-hub/history-service';
import type {EnvNameV3} from '@smart/endpoint-directory';
import type {LoginStateV3} from '@smart/login-service';
import type {LinkType} from '@smart/web-components';
import type {LinkContent} from '@smart/website-aem-utils';
import type {PageModel} from '@smart/website-page-model';
import {pushClickEvent} from '../client/push-click-event';

export interface CreateLinkPropsOptions {
  readonly historyService: HistoryServiceV3;
  readonly content: LinkContent;
  readonly consumerLocations?: ConsumerLocationV3[];
  readonly onBeforePush?: () => void;
  readonly pageModel?: PageModel;
  readonly eventTrackingData?: {
    type: string;
    region: string;
    name: string;
  };
  readonly envName?: EnvNameV3;
  readonly loginState?: LoginStateV3;
  readonly url?: string;
}

export function createLinkProps(options: CreateLinkPropsOptions): LinkType {
  const {
    historyService,
    content,
    consumerLocations = [],
    onBeforePush,
    pageModel,
    eventTrackingData,
    envName,
    loginState,
  } = options;

  const trackClick = (destinationUrl: string) => {
    const currentLocation = historyService.rootHistory.location;
    const currentUrl =
      typeof window !== `undefined`
        ? new URL(
            historyService.rootHistory.createHref({
              pathname: currentLocation.pathname,
              search: currentLocation.search,
            }),
            document.location.origin,
          ).href
        : ``;
    if (pageModel && eventTrackingData) {
      pushClickEvent(envName, loginState, pageModel, {
        ...eventTrackingData,
        url: currentUrl || ``,
        destinationUrl,
      });
    }
  };

  if (content.type === `absolute`) {
    return {
      href: content.url,
      label: content.label,
      isExternal: content.external,
      rel: content.external ? `noopener nofollow` : undefined,
      target: content.external ? `_blank` : undefined,
      onClick: () => {
        trackClick(content.url);
      },
    };
  }

  if (content.type === `anchor`) {
    return {
      href: content.anchor,
      label: content.label,
      onClick: () => {
        trackClick(content.anchor);
        window.location.href = content.anchor;
      },
    };
  }

  const {historyKey, rootHistory, createNewRootLocationForMultipleConsumers} =
    historyService;

  const {pathname, search, label, disableSpaNavigation} = content;

  const rootLocation = createNewRootLocationForMultipleConsumers(
    {historyKey, location: {pathname, search}},
    ...consumerLocations,
  );

  const href = rootHistory.createHref(rootLocation);

  return {
    href,
    label,
    onClick: disableSpaNavigation
      ? undefined
      : (event) => {
          const {altKey, ctrlKey, metaKey, shiftKey} = event;

          if (!altKey && !ctrlKey && !metaKey && !shiftKey) {
            onBeforePush?.();
            event.preventDefault();
            trackClick(href);
            rootHistory.push(rootLocation);
          }
        },
  };
}
