import {ImageStage} from '@smart/editorial-components';
import type {ImageStageContent} from '@smart/website-aem-utils';
import * as React from 'react';
import {useInStageNavigationItems} from '../hooks/use-in-stage-navigation-items';
import {useLink} from '../hooks/use-link';
import {createDisclamerLinkProps} from '../utils/universal/create-disclamer-link-props';

export interface ImageStageWrapperProps {
  readonly content: ImageStageContent;
  readonly pathname: string;
}

export const ImageStageWrapper = React.memo(
  React.forwardRef<HTMLElement, ImageStageWrapperProps>(
    ({content, pathname}, ref) => {
      const {headline, addOn, image, legalDisclaimers} = content;
      const link = useLink(content.link);

      const inStageNavigationItems = useInStageNavigationItems({
        addOn,
        pathname,
      });

      return (
        <ImageStage
          headline={headline}
          inStageNavigation={inStageNavigationItems}
          priceTag={addOn?.type === `price-tag` ? addOn.priceTag : undefined}
          image={image}
          link={link}
          legalDisclaimers={createDisclamerLinkProps(legalDisclaimers)}
          ref={ref}
        />
      );
    },
  ),
  (prevProps, nextProps) =>
    prevProps.content.id === nextProps.content.id &&
    prevProps.pathname === nextProps.pathname,
);

ImageStageWrapper.displayName = `ImageStageWrapper`;
