import {HeadlineWithImage} from '@smart/editorial-components';
import type {HeadlineWithImageContent} from '@smart/website-aem-utils';
import * as React from 'react';
import {useLink} from '../hooks/use-link';
import {createDisclamerLinkProps} from '../utils/universal/create-disclamer-link-props';

export interface HeadlineWithImageWrapperProps {
  readonly content: HeadlineWithImageContent;
}

export const HeadlineWithImageWrapper = React.memo(
  ({content}: HeadlineWithImageWrapperProps) => {
    const link = useLink(content.link);
    const {
      componentHeadline,
      headline,
      image,
      legalDisclaimers,
      highlight,
      indicatorBar,
    } = content;

    return (
      <HeadlineWithImage
        componentHeadline={componentHeadline}
        headline={headline}
        image={image}
        legalDisclaimers={createDisclamerLinkProps(legalDisclaimers)}
        highlight={highlight}
        indicatorBar={indicatorBar}
        link={link}
      />
    );
  },
  (prevProps, nextProps) => prevProps.content.id === nextProps.content.id,
);

HeadlineWithImageWrapper.displayName = `HeadlineWithImageWrapper`;
