import type {LinkType, NavigationItemType} from '@smart/web-components';
import type {HeaderNavigationItemContent} from '@smart/website-page-model';
import React from 'react';
import {createLinkProps} from '../utils/universal/create-link-props';
import {useFeatureServices} from './use-feature-services';

export interface UseHeaderNavigationItemsOptions {
  readonly navigationItemContents: readonly HeaderNavigationItemContent[];
  readonly logoutNavigationLinkLabel: string | undefined;
  readonly isLoggedIn: boolean;
  readonly closeMenu: () => void;
}

export function useHeaderNavigationItems({
  navigationItemContents,
  logoutNavigationLinkLabel,
  isLoggedIn,
  closeMenu,
}: UseHeaderNavigationItemsOptions): NavigationItemType {
  const {historyService, loginService} = useFeatureServices();

  return React.useMemo<NavigationItemType>(() => {
    const items: NavigationItemType = navigationItemContents.map((content) => {
      if (content.type === `menu-item`) {
        return {
          firstLevellabel: content.firstLevelLabel,
          secondLevelOverviewLink: createLinkProps({
            historyService,
            content: content.secondLevelOverviewDestination,
            onBeforePush: closeMenu,
          }),
          secondLevelSubItems: content.secondLevelSubItems.map(
            (subItemContent) =>
              createLinkProps({
                historyService,
                content: subItemContent,
                onBeforePush: closeMenu,
              }),
          ),
          campaign:
            content.campaignItems.length > 0
              ? {
                  headline: content.campaignHeadline,
                  items: content.campaignItems.map(({image, link, text}) => ({
                    ...image,
                    copyText: text,
                    link: createLinkProps({
                      historyService,
                      content: link,
                      onBeforePush: closeMenu,
                    }),
                  })),
                }
              : undefined,
        } satisfies Exclude<NavigationItemType[number], LinkType>;
      } else {
        return createLinkProps({
          historyService,
          content,
          onBeforePush: closeMenu,
        });
      }
    });

    if (isLoggedIn && logoutNavigationLinkLabel) {
      items.push({
        label: logoutNavigationLinkLabel,
        onClick: () => loginService?.logout(),
      });
    }

    return items;
  }, [
    navigationItemContents,
    logoutNavigationLinkLabel,
    isLoggedIn,
    closeMenu,
  ]);
}
