import type {EnvNameV3} from '@smart/endpoint-directory';
import {getCountryCode, getCurrency, getLanguage} from '@smart/locale-service';
import type {LoginStateV3} from '@smart/login-service';
import type {PageModel} from '@smart/website-page-model';
import {createTrackingUserData} from './create-tracking-user-data';
import type {DataLayerClickEvent} from './push-to-data-layer';
import {pushToDataLayer} from './push-to-data-layer';

export function pushClickEvent(
  envName: EnvNameV3 | undefined,
  loginState: LoginStateV3 | undefined,
  pageModel: PageModel,
  event: DataLayerClickEvent['eventInfo'],
): void {
  const {
    locale: {marketId, languageTag},
  } = pageModel;
  pushToDataLayer({
    event: `click`,
    eventInfo: event,
    user: createTrackingUserData(loginState),
    application: {
      id: `aem`,
      version: process.env.VERSION!,
      env: envName || `dev`,
      language: getLanguage(languageTag),
      market: getCountryCode(marketId).toLowerCase(),
      currency: getCurrency(marketId),
    },
  });
}
