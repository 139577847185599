import type {CarSlideType} from '@smart/editorial-components';
import {CarSlide} from '@smart/editorial-components';
import type {CarSlideContent} from '@smart/website-aem-utils/src/contents/create-car-slide-content';
import * as React from 'react';
import {useFeatureServices} from '../hooks/use-feature-services';
import {createDisclamerLinkProps} from '../utils/universal/create-disclamer-link-props';
import {createLinkProps} from '../utils/universal/create-link-props';
import {PageModelContext} from './page';

export interface CarSlideWrapperProps {
  readonly content: CarSlideContent;
  readonly cdnOrigin: string;
}

export const CarSlideWrapper = React.memo(
  ({content, cdnOrigin}: CarSlideWrapperProps) => {
    const pageModel = React.useContext(PageModelContext);
    const {endpointDirectory, loginService, historyService, pubSubService} =
      useFeatureServices();

    const props: CarSlideType = {
      removeTopSpacing: content.removeTopSpacing ?? false,
      pngSequenceBaseUrl: new URL(
        `${process.env.PNG_SEQUENCE_BASE_URL!}/car-slide`,
        cdnOrigin,
      ).href,
      models: content.models.map(
        (
          {
            modelName,
            modelDescription,
            priceTag,
            leasingPriceTag,
            priceTagAnnotation,
            cta,
            renderPopupButton,
            popupButtonText,
          },
          index,
        ) => ({
          title: modelName,
          subTitle: modelDescription,
          priceTag,
          leasingPriceTag,
          priceTagAnnotation,
          link: cta
            ? createLinkProps({
                historyService,
                content: cta,
                pageModel,
                eventTrackingData: {
                  type: `navigation`,
                  region: `slider_car`,
                  name: `${cta.label}.${index}`,
                },
                envName: endpointDirectory.envName,
                loginState: loginService?.loginState,
              })
            : undefined,
          renderPopupButton,
          popupButtonText,
        }),
      ),
      legalDisclaimers: createDisclamerLinkProps(content.legalDisclaimers),
      mode: content.modeSelector,
      publishFunc: pubSubService.publish,
    };

    return <CarSlide {...props} />;
  },
  (prevProps, nextProps) => prevProps.content.id === nextProps.content.id,
);

CarSlideWrapper.displayName = `CarSlideWrapper`;
