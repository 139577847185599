import type {ScrollytellingType, modeType} from '@smart/editorial-components';
import {Scrollytelling} from '@smart/editorial-components';
import type {ScrollytellingContent} from '@smart/website-aem-utils';
import * as React from 'react';

export interface ScrollytellingWrapperProps {
  readonly content: ScrollytellingContent;
}

export const ScrollytellingWrapper = React.memo(
  ({content}: ScrollytellingWrapperProps) => {
    const props: ScrollytellingType = {
      scrollyList: content.scrollyList.map(
        ({
          videoLandscape,
          videoPortrait,
          videoAltText,
          headline,
          description,
          listTitle,
          typeList,
          highlightTitle,
          highlightData,
          highlightDataUnit,
          highlightDescription,
          highlight2Title,
          highlight2Data,
          highlight2DataUnit,
          highlight2Description,
          cursorText,
          cursorDownText,
        }) => ({
          videoLandscape,
          videoPortrait,
          videoAltText,
          headline,
          description,
          listTitle,
          typeList,
          highlightTitle,
          highlightData,
          highlightDataUnit,
          highlightDescription,
          highlight2Title,
          highlight2Data,
          highlight2DataUnit,
          highlight2Description,
          cursorText,
          cursorDownText,
        }),
      ),
      removeTopSpacing: content.removeTopSpacing,
      soundOnLabel: ``,
      soundOffLabel: ``,
      mode: content.modeSelector as modeType,
    };
    return <Scrollytelling {...props} />;
  },
  (prevProps, nextProps) => prevProps.content.id === nextProps.content.id,
);

ScrollytellingWrapper.displayName = `ScrollytellingWrapper`;
