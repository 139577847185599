import type {Endpoint} from '@smart/endpoint-directory';
import {ToggleCheck} from '@smart/web-components';
import type {Versions} from '@smart/website-page-model/src/create-page-model';
import * as React from 'react';
import styles from './version-hashes.module.scss';

export type Props = {
  versions: Versions;
  envName: string;
  preview: boolean;
  aemEndpoint?: Endpoint;
};

export function VersionHashes({props}: {props: Props}) {
  const [showVersions, setShowVersions] = React.useState(false);
  const {versions, envName, preview, aemEndpoint} = props;
  return versions ? (
    <>
      <div className={styles.showVersionsButton}>
        <ToggleCheck
          label={`show hashes: ${envName}`}
          onChange={() => setShowVersions(!showVersions)}
        />
      </div>
      {showVersions && (
        <div className={styles.versionHahes}>
          <div className={styles.versionHashesInner}>
            <h1>{envName}</h1>
            {versions.items.map((version, index) => {
              return (
                <div className={styles.versionHash}>
                  <h4 key={`${version?.featureAppName} ${index}`}>
                    {version?.featureAppName}
                  </h4>
                  <p className={styles.hash}>{version?.versionHash}</p>
                  {preview && aemEndpoint ? (
                    <>
                      <hr />
                      <a
                        className={styles.hashLink}
                        href={`${aemEndpoint.origin}/ui#/aem/editor.html${version?._path}`}
                      >
                        change hash in AEM
                      </a>
                    </>
                  ) : (
                    `switch to preview to get AEM link`
                  )}
                  <hr />
                  <a
                    className={styles.hashLink}
                    href={`https://bitbucket.org/smart_eco-platform/${version?.featureAppName}-feature-app/commits/${version?.versionHash}`}
                  >
                    bitbucket
                  </a>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </>
  ) : null;
}
