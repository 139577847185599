import {
  ExtendedFlexibleGallery,
  type ExtendedFlexibleGalleryType,
} from '@smart/editorial-components';
import type {ExtendedFlexibleGalleryContent} from '@smart/website-aem-utils';
import * as React from 'react';
import {useFeatureServices} from '../hooks/use-feature-services';
import {createDisclamerLinkProps} from '../utils/universal/create-disclamer-link-props';
import {createLinkProps} from '../utils/universal/create-link-props';
import {PageModelContext} from './page';

export interface ExtendedFlexibleGalleryWrapperProps {
  readonly content: ExtendedFlexibleGalleryContent;
}

export const ExtendedFlexibleGalleryWrapper = React.memo(
  ({content}: ExtendedFlexibleGalleryWrapperProps) => {
    const pageModel = React.useContext(PageModelContext);
    const {endpointDirectory, loginService, historyService} =
      useFeatureServices();

    const props: ExtendedFlexibleGalleryType = {
      removeTopSpacing: content.removeTopSpacing ?? false,
      variant: content.variant,
      componentHeadline: content.componentHeadline,
      galleryItems: content.items.map(
        (
          {
            contentType,
            imageUrl,
            videoLandscapeUrl,
            videoPortraitUrl,
            videoPosterImageUrl,
            mediaAltText,
            headline,
            copyText,
            link,
            itemId,
          },
          index,
        ) => ({
          contentType,
          image:
            contentType === `image`
              ? {
                  imageUrl: imageUrl || ``,
                  imageAltText: mediaAltText || ``,
                }
              : undefined,
          video:
            contentType === `video`
              ? {
                  landscapeUrl: videoLandscapeUrl || ``,
                  portraitUrl: videoPortraitUrl || ``,
                  posterImageUrl: videoPosterImageUrl || ``,
                  altText: mediaAltText || ``,
                }
              : undefined,
          headline,
          copyText,
          link: link
            ? createLinkProps({
                historyService,
                content: link,
                pageModel,
                eventTrackingData: {
                  type: `navigation`,
                  region: `gallery_v2`,
                  name: `${link.label}.${index}`,
                },
                envName: endpointDirectory.envName,
                loginState: loginService?.loginState,
              })
            : undefined,
          itemId,
        }),
      ),
      legalDisclaimers: createDisclamerLinkProps(content.legalDisclaimers),
      mode: content.modeSelector,
    };

    return <ExtendedFlexibleGallery {...props} />;
  },
  (prevProps, nextProps) => prevProps.content.id === nextProps.content.id,
);

ExtendedFlexibleGalleryWrapper.displayName = `ExtendedFlexibleGalleryWrapper`;
