import {Heading, Text, TextLink} from '@smart/web-components';
import * as React from 'react';
import styles from './feature-app-maintenance-message.module.scss';

export interface FeatureAppMaintenanceMessageProps {
  readonly featureAppName: string;
}

const featureAppNamesWithMaintenanceMessage = [
  `addon`,
  `customizer`,
  `forms`,
  `line-selector`,
  `my-account`,
  `preorder`,
  `product-catalogue`,
  `product-summary`,
  `shopping-cart`,
  `stock-search`,
  `test-drive`,
];

export function FeatureAppMaintenanceMessage({
  featureAppName,
}: FeatureAppMaintenanceMessageProps): JSX.Element | null {
  if (!featureAppNamesWithMaintenanceMessage.includes(featureAppName)) {
    return null;
  }

  return (
    <section className={styles.section} data-nosnippet>
      <Heading
        variant="display-emotional-300"
        tag="div"
        className={styles.headline}
      >
        We are updating our systems.
      </Heading>
      <Text>
        Please be patient. This functionality will be available again soon.
      </Text>
      <TextLink
        variant="button-secondary"
        className={styles.button}
        href="/"
        linkText="Browse Website"
      />
    </section>
  );
}
