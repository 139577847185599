import {ComponentProvider} from '@smart/react-components';
import type {PageModel} from '@smart/website-page-model';
import * as React from 'react';
import {useEffect} from 'react';
import {Page} from './components/page';
import type {MaintenanceMode} from './utils/server/fetch-maintenance-mode';

export interface AppProps {
  readonly initialPageModel: PageModel;
  readonly debug?: boolean;
  readonly maintenanceMode?: MaintenanceMode;
}

export function App({
  initialPageModel,
  debug,
  maintenanceMode,
}: AppProps): JSX.Element {
  useEffect(() => {
    document.body.classList.add(`newDesignToken`);
    return () => {
      document.body.classList.remove(`newDesignToken`);
    };
  }, []);

  return (
    <ComponentProvider
      theme="light"
      locale={initialPageModel.locale.languageTag}
    >
      <React.Suspense>
        <Page
          initialPageModel={initialPageModel}
          debug={debug}
          maintenanceMode={maintenanceMode}
        />
      </React.Suspense>
    </ComponentProvider>
  );
}
