import type {StatementType, modeType} from '@smart/editorial-components';
import {Statement} from '@smart/editorial-components';
import type {StatementContent} from '@smart/website-aem-utils';
import * as React from 'react';
import {useLink} from '../hooks/use-link';
import {PageModelContext} from './page';

export interface StatementWrapperProps {
  readonly content: StatementContent;
}

export const StatementWrapper = React.memo<StatementWrapperProps>(
  ({content}) => {
    const pageModel = React.useContext(PageModelContext);
    const primaryLink = useLink(content.primaryLink, {
      pageModel,
      eventTrackingData: {
        type: `navigation`,
        region: `statement`,
        name: content.primaryLink?.label || ``,
      },
    });
    const secondaryLink = useLink(content.secondaryLink, {
      pageModel,
      eventTrackingData: {
        type: `navigation`,
        region: `statement`,
        name: content.secondaryLink?.label || ``,
      },
    });

    const props: StatementType = {
      removeTopSpacing: content.removeTopSpacing,
      mode: content.mode as modeType,
      subline: content.subline,
      headline: content.headline,
      primaryLink,
      secondaryLink,
    };

    return <Statement {...props} />;
  },
  (prevProps, nextProps) => prevProps.content.id === nextProps.content.id,
);

StatementWrapper.displayName = `StatementWrapper`;
