import React, {useState} from 'react';
import {Helmet} from 'react-helmet-async';
import {useFeatureServices} from '../../../hooks/use-feature-services';
import {INACTIVITY_TIMEOUT_MS} from '../constants';
import {
  useHistoryPath,
  useInactivityCallback,
  useInterruptionCallback,
  useLocale,
} from '../hooks';
import type {VoiceOfCustomerTypeProps} from '../types';

export const InterruptionPopup = ({
  config: {id, script},
}: VoiceOfCustomerTypeProps) => {
  const [hasPopupBeenDisplayed, setHasPopupBeenDisplayed] = useState(false);
  const [isUserInactive, setIsUserInactive] = useState(false);
  const path = useHistoryPath();
  const locale = useLocale();
  const {adbContextService} = useFeatureServices();

  useInactivityCallback({
    callback: () => {
      setHasPopupBeenDisplayed(true);
      setIsUserInactive(true);
    },
    enabled: !hasPopupBeenDisplayed,
    locale,
    path,
    timeout: INACTIVITY_TIMEOUT_MS,
  });

  useInterruptionCallback({
    callback: setHasPopupBeenDisplayed,
    locale,
    path,
  });

  if (adbContextService?.adbContext?.hasCustomer) return null;
  if (!hasPopupBeenDisplayed) return null;

  // NOTE: DO NOT MODIFY SCRIPT. Used by qualtrics to track interruption
  const qualtricsInterruptionActiveScript = `var isInterruptionActive = ${hasPopupBeenDisplayed};`;

  // NOTE: DO NOT MODIFY SCRIPT. Used by qualtrics to track inactivity
  const qualtricsInactivityActiveScript = `var isUserInactive = ${isUserInactive};`;

  return (
    <>
      <Helmet>
        <script type="text/javascript">{script}</script>
        {/* NOTE: DO NOT MODIFY ID. Used by qualtrics to track interruption */}
        <script type="text/javascript" id="qualtrics-interruption-script">
          {qualtricsInterruptionActiveScript}
        </script>
        {/* NOTE: DO NOT MODIFY ID. Used by qualtrics to track inactivity */}
        <script type="text/javascript" id="qualtrics-inactivity-script">
          {qualtricsInactivityActiveScript}
        </script>
      </Helmet>
      <div id={id}></div>
    </>
  );
};
