import {FlexibleTextImage} from '@smart/editorial-components';
import type {FlexibleTextAndImageContent} from '@smart/website-aem-utils';
import * as React from 'react';
import {useLink} from '../hooks/use-link';
import {createDisclamerLinkProps} from '../utils/universal/create-disclamer-link-props';

export interface FlexibleTextAndImageWrapperProps {
  readonly content: FlexibleTextAndImageContent;
}

export const FlexibleTextAndImageWrapper = React.memo(
  ({content}: FlexibleTextAndImageWrapperProps) => {
    const primaryLink = useLink(content.primaryLink);
    const secondaryLink = useLink(content.secondaryLink);

    const {
      componentHeadline,
      headline,
      priceTag,
      copyText,
      image,
      imageLeading,
      highlight,
      legalDisclaimers,
      indicatorBar,
    } = content;

    return (
      <FlexibleTextImage
        componentHeadline={componentHeadline}
        headline={headline}
        priceTag={priceTag}
        copyText={copyText}
        image={image}
        primaryLink={primaryLink}
        link={secondaryLink}
        imageLeading={imageLeading}
        highlight={highlight}
        legalDisclaimers={createDisclamerLinkProps(legalDisclaimers)}
        indicatorBar={indicatorBar}
      />
    );
  },
  (prevProps, nextProps) => prevProps.content.id === nextProps.content.id,
);

FlexibleTextAndImageWrapper.displayName = `FlexibleTextAndImageWrapper`;
