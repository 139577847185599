import type {FlexibleGalleryType} from '@smart/editorial-components';
import {FlexibleGallery} from '@smart/editorial-components';
import type {FlexibleGalleryContent} from '@smart/website-aem-utils';
import * as React from 'react';
import {useLink} from '../hooks/use-link';
import {createDisclamerLinkProps} from '../utils/universal/create-disclamer-link-props';

export interface FlexibleGalleryWrapperProps {
  readonly content: FlexibleGalleryContent;
}

type GalleryItem = FlexibleGalleryType['galleryItems'][number];

export const FlexibleGalleryWrapper = React.memo(
  ({content}: FlexibleGalleryWrapperProps) => {
    const {
      headline: componentHeadline,
      items,
      highlight = false,
      legalDisclaimers,
    } = content;

    const link = useLink(content.link);

    const galleryItems = React.useMemo(
      (): GalleryItem[] =>
        items.map(
          ({
            headline,
            subheadline,
            captionHeadline,
            captionCopyText,
            image,
          }) => ({
            imageHeadline: headline,
            imageCopyText: subheadline,
            imageCaptionHeadline: captionHeadline,
            imageCaptionCopyText: captionCopyText,
            image,
          }),
        ),
      [items],
    );

    return (
      <FlexibleGallery
        componentHeadline={componentHeadline}
        galleryItems={galleryItems}
        link={link}
        highlight={highlight}
        legalDisclaimers={createDisclamerLinkProps(legalDisclaimers)}
      />
    );
  },
  (prevProps, nextProps) => prevProps.content.id === nextProps.content.id,
);

FlexibleGalleryWrapper.displayName = `FlexibleGalleryWrapper`;
