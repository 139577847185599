import type {MediaStageType, modeType} from '@smart/editorial-components';
import {MediaStage} from '@smart/editorial-components';
import type {MediaStageContent} from '@smart/website-aem-utils';
import * as React from 'react';
import {createDisclamerLinkProps} from '../utils/universal/create-disclamer-link-props';
export interface MediaStageWrapperProps {
  readonly content: MediaStageContent;
  readonly cdnOrigin: string;
}

const customVideoStylesBaseUrl = process.env.CUSTOM_VIDEO_STYLES_BASE_URL!;

export const MediaStageWrapper = React.memo<MediaStageWrapperProps>(
  ({content, cdnOrigin}) => {
    const props: MediaStageType = {
      customVideoStylesBaseUrl: new URL(customVideoStylesBaseUrl, cdnOrigin)
        .href,
      removeTopSpacing: content.removeTopSpacing,
      mode: content.mode as modeType,
      aspectRatio: content.aspectRatio || `16/9`,
      enableSideSpacing: content.enableSideSpacing,
      contentType: content.contentType,
      image:
        content.contentType === `image`
          ? {
              imageUrl: content.imageUrl || ``,
              imageAltText: content.altText || ``,
            }
          : undefined,
      video:
        content.contentType === `video`
          ? {
              landscapeUrl: content.videoLandscapeUrl || ``,
              portraitUrl: content.videoPortraitUrl || ``,
              altText: content.altText || ``,
            }
          : undefined,
      legalDisclaimers: createDisclamerLinkProps(content.legalDisclaimers),
    };

    return <MediaStage {...props} />;
  },
  (prevProps, nextProps) =>
    prevProps.content.id === nextProps.content.id &&
    prevProps.cdnOrigin === nextProps.cdnOrigin,
);

MediaStageWrapper.displayName = `MediaStageWrapper`;
