import type {TeaserType} from '@smart/editorial-components';
import {TeaserRow} from '@smart/editorial-components';
import type {TeaserRowContent} from '@smart/website-aem-utils';
import * as React from 'react';
import {useFeatureServices} from '../hooks/use-feature-services';
import {createLinkProps} from '../utils/universal/create-link-props';

export interface TeaserRowWrapperProps {
  readonly content: TeaserRowContent;
}

export const TeaserRowWrapper = React.memo(
  ({content}: TeaserRowWrapperProps) => {
    const {historyService} = useFeatureServices();

    const teasers = React.useMemo(
      () =>
        content.items.map<TeaserType>(({imageUrl, imageAltText, link}) => ({
          image: {imageUrl, imageAltText},
          link: createLinkProps({historyService, content: link}),
        })),
      [content.items],
    );

    return (
      <TeaserRow
        componentHeadline={content.componentHeadline}
        indicatorBar={content.indicatorBar}
        teaserList={teasers}
      />
    );
  },
  (prevProps, nextProps) => prevProps.content.id === nextProps.content.id,
);

TeaserRowWrapper.displayName = `TeaserRowWrapper`;
