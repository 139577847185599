import {useEffect, useState} from 'react';
import {useFeatureServices} from '../../../hooks/use-feature-services';

export interface HistoryPath {
  currentPath: string;
  previousPath: string;
}
export const useHistoryPath = () => {
  const {historyService} = useFeatureServices();
  const [historyState, setHistoryState] = useState<HistoryPath>({
    currentPath: historyService.rootHistory.location.pathname,
    previousPath: ``,
  });

  useEffect(() => {
    const unlisten = historyService.rootHistory.listen(({location}) => {
      setHistoryState((prevState) => ({
        currentPath: location.pathname,
        previousPath: prevState.currentPath,
      }));
    });

    return () => {
      unlisten();
    };
  }, [historyService]);

  return historyState;
};
