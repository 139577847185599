import React from 'react';
import {useFeatureServices} from '../../hooks/use-feature-services';
import {InterruptionPopup, Survey} from './components';
import {getConfiguration} from './utils';

interface VoiceOfCustomerProps {
  isSurveyEnabled: boolean;
  isInterruptionPopupEnabled: boolean;
}
export const VoiceOfCustomer = ({
  isSurveyEnabled,
  isInterruptionPopupEnabled,
}: VoiceOfCustomerProps) => {
  const {
    endpointDirectory: {envName},
  } = useFeatureServices();

  const config = getConfiguration(envName);

  return (
    <>
      {isSurveyEnabled && <Survey config={config.survey} />}
      {isInterruptionPopupEnabled && (
        <InterruptionPopup config={config.interruption} />
      )}
    </>
  );
};
