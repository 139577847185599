import type {EnvNameV3} from '@smart/endpoint-directory';
import type {LoginStateV3} from '@smart/login-service';
import type {LinkType} from '@smart/web-components';
import type {LinkContent} from '@smart/website-aem-utils';
import type {PageModel} from '@smart/website-page-model';
import * as React from 'react';
import {createLinkProps} from '../utils/universal/create-link-props';
import {useFeatureServices} from './use-feature-services';

type useLinksOptions = {
  readonly pageModel?: PageModel;
  readonly eventTrackingData?: {
    type: string;
    region: string;
  };
  readonly envName?: EnvNameV3;
  readonly loginState?: LoginStateV3;
};

export function useLinks(
  contents: readonly LinkContent[],
  useLinksOptions?: useLinksOptions,
): LinkType[] {
  const {historyService} = useFeatureServices();

  return React.useMemo<LinkType[]>(
    () =>
      contents.map((content) =>
        createLinkProps({
          historyService,
          content,
          pageModel: useLinksOptions?.pageModel,
          eventTrackingData:
            useLinksOptions && useLinksOptions.eventTrackingData
              ? {...useLinksOptions.eventTrackingData, name: content.label}
              : undefined,
          envName: useLinksOptions?.envName,
          loginState: useLinksOptions?.loginState,
        }),
      ),
    [contents],
  );
}
