import {
  INTERRUPTION_SCRIPT_PROD,
  INTERRUPTION_SCRIPT_STAGE,
  SURVEY_SCRIPT_PROD,
  SURVEY_SCRIPT_STAGE,
} from './scripts';

export const CONFIGURATION = {
  interruption: {
    stage: {
      script: INTERRUPTION_SCRIPT_STAGE,
      id: `ZN_efW3TGZUJP1XpwG`,
    },
    prod: {
      script: INTERRUPTION_SCRIPT_PROD,
      id: ``, //TODO implement prod id once retrieved from Qualtrics
    },
  },
  survey: {
    stage: {
      script: SURVEY_SCRIPT_STAGE,
      id: `ZN_1FVOdDH2NjNmbZA`,
    },
    prod: {
      script: SURVEY_SCRIPT_PROD,
      id: `ZN_9uVa5xKJWrsoMRw`,
    },
  },
} as const;

export const INTERRUPTION_START_PATHS: ReadonlyArray<string> = [
  `/checkout/`,
  `/cart/`,
];

export const INTERRUPTION_END_PATHS: ReadonlyArray<string> = [
  `/`,
  `/products/`,
];

const ONE_MINUTE_MS = 60 * 1000;

export const INACTIVITY_TIMEOUT_MS = 3 * ONE_MINUTE_MS;
